import {
	Heading,
	useDisclosure,
	AlertDialog,
	AlertDialogBody,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	ButtonGroup,
	Checkbox,
	CheckboxGroup,
	Editable,
	EditableInput,
	EditablePreview,
	FormControl,
	FormErrorMessage,
	FormLabel,
	IconButton,
	Input,
	Select,
	useEditableControls,
	useToast,
	VStack,
	Tooltip,
	Box,
	Text,
	UnorderedList,
	ListItem,
} from '@chakra-ui/react';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BsArrowLeft } from 'react-icons/bs';
import { FiCheck, FiEdit } from 'react-icons/fi';
import { IoMdClose } from 'react-icons/io';
import { useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
	useUpdateClientMailTemplate,
	useUpdateDocumentStatus,
	useUpdateReminderTemplate,
} from '../../state/server-side/mutations/documents-status';
import { useFetchInsurerData } from '../../state/server-side/queries/documents-status';
import RichTextEditor from './editor/editor';

const NewEdit = () => {
	const navigate = useNavigate();
	const { insurerId, statusId } = useParams();
	const { data, isLoading } = useFetchInsurerData(insurerId, {
		select: data => data.find(status => status.id === +statusId),
	});

	const toast = useToast();
	const updateStatus = useUpdateDocumentStatus();
	const updateReminder = useUpdateReminderTemplate();
	const updateClientMail = useUpdateClientMailTemplate();
	const queryClient = useQueryClient();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = useRef();
	const [showReminderIntervalTime, setshowReminderIntervalTime] = useState(data.reminderInterval === 'i');

	function EditableControls() {
		const { isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps } = useEditableControls();

		return isEditing ? (
			<ButtonGroup justifyContent="center" size="sm">
				<IconButton icon={<FiCheck />} {...getSubmitButtonProps()} />
				<IconButton icon={<IoMdClose />} {...getCancelButtonProps()} />
			</ButtonGroup>
		) : (
			<IconButton size="sm" icon={<FiEdit />} {...getEditButtonProps()} />
		);
	}

	const handleSave = () => {
		updateStatus.mutate(
			{ statusId: data.id, mutationData: { oldStatus: data, newStatus: getValues() } },
			{
				onSuccess: () => {
					queryClient.invalidateQueries(['insurers', `${data.insurerId}`]);
					onClose();
					// handleCloseEdit();
					toast({
						description: `Configurari salvate!`,
						position: 'top-right',
						status: 'success',
						duration: 5000,
						isClosable: true,
					});
				},
				onError: error => {
					onClose();
					toast({
						title: 'Eroare!',
						description: error.message,
						position: 'top-right',
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				},
			}
		);
	};

	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting, dirtyFields },
		reset,
		getValues,
	} = useForm({
		mode: 'onChange',
	});

	function onSubmit(values) {
		// updateStatus.mutate({ statusId: status.id, mutationData: { oldStatus: status, newStatus: values } });
		onOpen();
	}

	const handleCloseEdit = () => {
		reset();
		navigate(-1);
	};

	if (isLoading) return <div>Loading...</div>;

	const listChanges = () => {
		return Object.keys(dirtyFields).map(field => {
			if (field === 'name') return 'Nume status';
			if (field === 'deadline') return 'Termen limita';
			if (field === 'deadlineUnit') return 'Unitate termen limita';
			if (field === 'mailToAdvisor') return 'Catre consultant';
			if (field === 'mailToSuperior') return 'Catre superior';
			if (field === 'mailToDevizieri') return 'Catre devizieri';
			if (field === 'mailToAchizitori') return 'Catre achizitori';
			if (field === 'mailToClient') return 'Catre client';
			if (field === 'reminderInterval') return 'Interval retrimitere mail la expirarea termenului limita';
			if (field === 'reminderIntervalTime') return 'O data la cate ore?';
			return null;
		});
	};

	return (
		<>
			<Heading as={'h2'} mb={6}>
				<Tooltip label="Inapoi">
					<IconButton variant={'ghost'} icon={<BsArrowLeft />} mr={2} onClick={handleCloseEdit} />
				</Tooltip>
				Configurare {data.name}
			</Heading>
			<form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
				<FormControl isInvalid={errors.name}>
					<FormLabel htmlFor="name">Nume status</FormLabel>
					<Editable defaultValue={data.name} isPreviewFocusable={false} display={'inline'}>
						<EditablePreview mr={3} />
						<Input as={EditableInput} {...register('name')} />
						<EditableControls />
					</Editable>
					<FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
				</FormControl>

				<FormControl isInvalid={errors.deadline} mb={2}>
					<FormLabel htmlFor="deadline">Termen limita</FormLabel>
					<Editable defaultValue={data.deadline} isPreviewFocusable={false} display={'inline'}>
						<EditablePreview mr={3} />
						<Input as={EditableInput} {...register('deadline')} />
						<EditableControls />
					</Editable>
					<FormErrorMessage>{errors.deadline && errors.deadline.message}</FormErrorMessage>
				</FormControl>

				<FormControl>
					<FormLabel htmlFor="deadlineUnit">Unitate termen limita</FormLabel>
					<Select
						id="deadlineUnit"
						{...register('deadlineUnit', {
							value: data.deadlineUnit,
						})}
					>
						<option value="h">ore</option>
						<option value="d">zile</option>
					</Select>
					<FormErrorMessage>{errors.deadlineUnit && errors.deadlineUnit.message}</FormErrorMessage>
				</FormControl>

				<FormControl as="fieldset" isInvalid={errors.mailToAdvisor} mt={2}>
					<FormLabel as="legend">Trimitere mail</FormLabel>
					<CheckboxGroup>
						<VStack spacing={2} alignItems="flex-start">
							<Checkbox
								{...register('mailToAdvisor', {
									value: data.mailToAdvisor,
									required: 'Mailul catre CONSULTANT e obligatoriu!',
								})}
							>
								Catre consultant (la incarcarea dovezii & la expirarea statusului)
							</Checkbox>
							<Checkbox
								{...register('mailToSuperior', {
									value: data.mailToSuperior,
								})}
							>
								Catre superior (la expirarea statusului)
							</Checkbox>
							<Checkbox
								{...register('mailToDevizieri', {
									value: data.mailToDevizieri,
								})}
							>
								Catre devizieri (la inceperea statusului)
							</Checkbox>
							<Checkbox
								{...register('mailToAchizitori', {
									value: data.mailToAchizitori,
								})}
							>
								Catre achizitori (la inceperea statusului)
							</Checkbox>
							<Checkbox
								{...register('mailToClient', {
									value: data.mailToClient,
								})}
							>
								Catre client (la incarcarea dovezii)
							</Checkbox>
						</VStack>
					</CheckboxGroup>
					<FormErrorMessage>{errors.mailToAdvisor && errors.mailToAdvisor.message}</FormErrorMessage>
				</FormControl>

				<FormControl mt={2}>
					<FormLabel htmlFor="reminderInterval">
						Interval retrimitere mail la expirarea termenului limita
					</FormLabel>
					<Select
						id="reminderInterval"
						{...register('reminderInterval', {
							value: data.reminderInterval,
							onChange: e =>
								e.target.value === 'i' ? setshowReminderIntervalTime(true) : setshowReminderIntervalTime(false),
						})}
					>
						<option value="d">Zilnic</option>
						<option value="i">Interval</option>
					</Select>
				</FormControl>

				{showReminderIntervalTime && (
					<FormControl isInvalid={errors.reminderIntervalTime} mt={2}>
						<FormLabel htmlFor="reminderIntervalTime">O data la cate ore?</FormLabel>
						<Editable defaultValue={data.reminderIntervalTime} isPreviewFocusable={false} display={'inline'}>
							<EditablePreview mr={3} />
							<Input
								as={EditableInput}
								{...register('reminderIntervalTime', {
									min: {
										value: 1,
										message: 'Nu poate fi mai putin de 1!',
									},
									validate: {
										required: value => {
											if (!value && showReminderIntervalTime)
												return 'Camp necesar cand intervalul este "Interval".';
											return true;
										},
									},
								})}
							/>
							<EditableControls />
						</Editable>
						<FormErrorMessage>
							{errors.reminderIntervalTime && errors.reminderIntervalTime.message}
						</FormErrorMessage>
					</FormControl>
				)}

				{/* <Alert status="error" mt={8}>
					<AlertIcon />
					{JSON.stringify(watch(), null, 2)}
				</Alert> */}
				<Box mt={2}>
					<Button variant="outline" mr={3} onClick={handleCloseEdit}>
						Renunta
					</Button>
					<Button colorScheme={'blue'} isLoading={isSubmitting} type="submit">
						Salveaza configurari
					</Button>
				</Box>
				<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
					<AlertDialogOverlay>
						<AlertDialogContent>
							<AlertDialogHeader fontSize="lg" fontWeight="bold">
								Confirma modificare
							</AlertDialogHeader>

							<AlertDialogBody>
								<Text>Ai modificat campurile:</Text>
								<UnorderedList>
									{listChanges().map(item => (
										<ListItem key={item} fontWeight={'bold'}>
											{item}
										</ListItem>
									))}
								</UnorderedList>
								<Text>Esti sigur ca vrei sa faci modificarea?</Text>
							</AlertDialogBody>

							<AlertDialogFooter>
								<Button ref={cancelRef} onClick={onClose}>
									Renunta
								</Button>
								<Button colorScheme="red" onClick={handleSave} ml={3}>
									Salveaza
								</Button>
							</AlertDialogFooter>
						</AlertDialogContent>
					</AlertDialogOverlay>
				</AlertDialog>
			</form>
			<RichTextEditor type="reminder" content={data.reminderTemplate} updateTemplate={updateReminder} />
			{data.mailToClient ? (
				<RichTextEditor type="client" content={data.clientMailTemplate} updateTemplate={updateClientMail} />
			) : null}
		</>
	);
};

export default NewEdit;
