import {
	Button,
	Checkbox,
	CheckboxGroup,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	FormControl,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	Input,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Select,
	useToast,
	VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { useCreateDocumentStatus } from '../../state/server-side/mutations/documents-status';
import { useQueryClient } from 'react-query';

const AddDocumentStatusDrawer = ({ isOpen, onClose, btnRef, insurerId }) => {
	const toast = useToast();
	const [showReminderInterval, setshowReminderInterval] = useState(false);
	const [showReminderIntervalTime, setshowReminderIntervalTime] = useState(false);
	const createStatus = useCreateDocumentStatus();
	const queryClient = useQueryClient();

	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting },
		reset,
		getValues,
	} = useForm();

	function onSubmit(values) {
		createStatus.mutate(
			{ insurerId, mutationData: { ...values } },
			{
				onSuccess: data => {
					queryClient.invalidateQueries(['insurers', `${insurerId}`]);
					handleClose();
					toast({
						description: `Status ${data.Denumire} (ID: ${data.IdStatus}) a fost creat cu succes!`,
						status: 'success',
						duration: 3000,
						isClosable: true,
					});
				},
				onError: error => {
					toast({
						title: 'Eroare!',
						description: error.message,
						status: 'error',
						duration: 5000,
						isClosable: true,
					});
				},
			}
		);
	}

	const handleClose = () => {
		reset();
		setshowReminderInterval(false);
		setshowReminderIntervalTime(false);
		onClose();
	};

	return (
		<Drawer size={'md'} isOpen={isOpen} placement="right" onClose={handleClose} finalFocusRef={btnRef}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader>Adauga status nou</DrawerHeader>

				<form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
					<DrawerBody>
						<FormControl isInvalid={errors.name}>
							<FormLabel htmlFor="name">Nume status</FormLabel>
							<Input
								id="name"
								type="text"
								{...register('name', {
									required: 'Numele este obligatoriu!',
								})}
							/>
							<FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
						</FormControl>

						<Flex mt={2}>
							<FormControl isInvalid={errors.deadline} flex={2}>
								<FormLabel htmlFor="deadline">Termen limita</FormLabel>
								<NumberInput>
									<NumberInputField
										id="deadline"
										min={0}
										{...register('deadline', {
											onBlur: e =>
												e.target.value && +e.target.value !== 0
													? setshowReminderInterval(true)
													: setshowReminderInterval(false),
											value: 0,
											required: 'Termenul limita este obligatoriu! Daca nu vrei termen limita, seteaza 0.',
											min: {
												value: 0,
												message: 'Nu poate fi mai putin de 0!',
											},
										})}
									/>
									<NumberInputStepper>
										<NumberIncrementStepper />
										<NumberDecrementStepper />
									</NumberInputStepper>
								</NumberInput>
								<FormHelperText>
									Valoarea <strong>0</strong> elimina termenul limita de pe acest status.
								</FormHelperText>
								<FormErrorMessage>{errors.deadline && errors.deadline.message}</FormErrorMessage>
							</FormControl>

							<FormControl flex={1} ml={2}>
								<FormLabel htmlFor="deadlineUnit">Unitate</FormLabel>
								<Select id="deadlineUnit" {...register('deadlineUnit')}>
									<option value="h">ore</option>
									<option value="d">zile</option>
								</Select>
							</FormControl>
						</Flex>

						<FormControl as="fieldset" isInvalid={errors.mailToAdvisor} mt={2}>
							<FormLabel as="legend">Trimitere mail</FormLabel>
							<CheckboxGroup>
								<VStack spacing={2} alignItems="flex-start">
									<Checkbox
										{...register('mailToAdvisor', {
											value: true,
											required: 'Mailul catre CONSULTANT e obligatoriu!',
										})}
									>
										Catre consultant (la incarcarea dovezii & la expirarea statusului)
									</Checkbox>
									<Checkbox {...register('mailToSuperior')}>Catre superior (la expirarea statusului)</Checkbox>
									<Checkbox {...register('mailToDevizieri')}>
										Catre devizieri (la inceperea statusului)
									</Checkbox>
									<Checkbox {...register('mailToAchizitori')}>
										Catre achizitori (la inceperea statusului)
									</Checkbox>
									<Checkbox {...register('mailToClient')}>Catre client (la incarcarea dovezii)</Checkbox>
								</VStack>
							</CheckboxGroup>
							<FormErrorMessage>{errors.mailToAdvisor && errors.mailToAdvisor.message}</FormErrorMessage>
						</FormControl>

						{showReminderInterval && (
							<>
								<FormControl mt={2}>
									<FormLabel htmlFor="reminderInterval">
										Interval retrimitere mail la expirarea termenului limita
									</FormLabel>
									<Select
										id="reminderInterval"
										{...register('reminderInterval', {
											onChange: e =>
												e.target.value === 'i'
													? setshowReminderIntervalTime(true)
													: setshowReminderIntervalTime(false),
										})}
									>
										<option value="d">Zilnic</option>
										<option value="i">Interval</option>
									</Select>
								</FormControl>
								{showReminderIntervalTime && (
									<FormControl isInvalid={errors.reminderIntervalTime} mt={2}>
										<FormLabel htmlFor="reminderIntervalTime">O data la cate ore?</FormLabel>
										<NumberInput>
											<NumberInputField
												id="reminderIntervalTime"
												min={0}
												{...register('reminderIntervalTime', {
													value: 0,
													min: {
														value: 1,
														message: 'Nu poate fi mai putin de 1!',
													},
													validate: {
														required: value => {
															if (!value && getValues('reminderInterval') === 'i')
																return 'Required when reminderInterval is i';
															return true;
														},
													},
												})}
											/>
											<NumberInputStepper>
												<NumberIncrementStepper />
												<NumberDecrementStepper />
											</NumberInputStepper>
										</NumberInput>
										<FormErrorMessage>
											{errors.reminderIntervalTime && errors.reminderIntervalTime.message}
										</FormErrorMessage>
									</FormControl>
								)}
							</>
						)}

						{/* <Alert status="error" mt={8}>
							<AlertIcon />
							{JSON.stringify(watch(), null, 2)}
						</Alert> */}
					</DrawerBody>

					<DrawerFooter>
						<Button variant="outline" mr={3} onClick={handleClose}>
							Renunta
						</Button>
						<Button colorScheme={'blue'} isLoading={isSubmitting} type="submit">
							Adauga
						</Button>
					</DrawerFooter>
				</form>
			</DrawerContent>
		</Drawer>
	);
};

export default AddDocumentStatusDrawer;
